export type LogFunction = (message: string | Error) => void;

export interface Logger {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

const logLevels = ['error', 'warn', 'info', 'debug'];

const isLevelToOutput = (level: string): boolean => {
  // webpack config で指定される想定
  const threshold: string = LOG_LEVEL;
  const index = logLevels.indexOf(level);
  return (
    logLevels.indexOf(threshold) >=
    (index !== -1 ? index : Number.MAX_SAFE_INTEGER)
  );
};

export function getLogger(url: string): Logger {
  const fileName = url.split('/').pop() ?? 'unknown';
  const f = (level: string) => {
    if (isLevelToOutput(level)) {
      return (msg: string | Error) => {
        msg = msg.toString();
        console.log(
          `[${level.toUpperCase()}][${new Date().toISOString()}] ${fileName}: ${msg}`
        );
      };
    } else {
      return () => 0;
    }
  };
  return {
    debug: f('debug'),
    info: f('info'),
    warn: f('warn'),
    error: f('error'),
  };
}
