import * as React from 'react';
import { render } from 'react-dom';
import App from './app/App';
import './config/I18nConfig'; //i18

// async/awaitを使用するために必要
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const rootEl = document.getElementById('root');
render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>GT STATS</title>
        <meta
          name="description"
          content="SUPER GTとJGTCのデータベースです。優勝数や表彰台数のランキングを、選手や監督、車メーカー、タイヤ、サーキットなどの観点で集計・分析しています。"
        />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  rootEl
);
