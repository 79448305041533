import { StringMap, TOptions } from 'i18next';
import { TFunction, useTranslation } from 'react-i18next';
import { getLogger } from '../utils/Logger';
import { defined, normalizeKana, Optional, returnIf } from '../utils/Util';

const logger = getLogger(import.meta.url);

type TranslationFuncType = TFunction<string[], undefined>;

const NAMESPACE = {
  LABELS: 'labels',
  URLS: 'urls',
  DATA: 'data',
};

export const LABEL_CATEGORY = {
  ACHIEVEMENT: 'achievement',
  COMMON: 'common',
  TABLE_COMMON: 'table_common',
  TABLE_CATEGORY: 'table_category',
  PRODUCER: 'producer',
  RANKING: 'ranking',
  VALUE: 'value',
} as const;

type LabelCategory = typeof LABEL_CATEGORY[keyof typeof LABEL_CATEGORY];

const LOCALIZE_KEY_NAMES: Record<string, string> = {
  car_name: 'car',
  circuit: 'circuit',
  director: 'people',
  driver_name: 'people',
  driver_name_in_pair: 'people',
  fastest_driver_name: 'people',
  entrant: 'company',
  machine: 'machine',
  manufacturer: 'manufacturer',
  tyre: 'tyre',
  people: 'people',
  race_kind: 'race_kind',
} as const;

export const getLocalizeKeyNames = (): string[] => {
  return Object.keys(LOCALIZE_KEY_NAMES);
};

export type I18n = {
  DATA: Category<string, string>;
  DATA_KANA: Category<string, string>;
  LABELS: Category<LabelCategory, string>;
  URLS: StringUrlCategory;
};

class Category<T extends string, V> {
  constructor(
    private readonly namespace: typeof NAMESPACE[keyof typeof NAMESPACE],
    private readonly t: TranslationFuncType,
    private readonly convertKeyFunc: (key: T) => Optional<T>,
    private readonly defaultKey: Optional<T>,
    private readonly defaultValueFunc: (ids: Optional<string>[]) => V
  ) {}

  localize<U extends string | Optional<string>>(
    idOrIds: U | U[],
    key?: Optional<T>,
    options?: string | TOptions<StringMap> | undefined
  ): Exclude<U, undefined> | V {
    if (!defined(key)) {
      key = this.defaultKey;
    }
    const ids: string[] = [];
    for (const id of typeof idOrIds === 'object' ? idOrIds : [idOrIds]) {
      if (typeof id === 'string') {
        ids.push(id);
      }
    }

    const defaultValue = this.defaultValueFunc(ids);

    if (!defined(key)) {
      return defaultValue;
    }
    const category = this.convertKeyFunc(key) ?? key;
    for (const id of ids) {
      if (!defined(id)) {
        continue;
      }
      const k = this.namespace + ':' + category + '.' + id;
      const localized: V = this.t(k, options);
      // 翻訳できない場合はidと同じ値が返る。NSを取り除いて比較
      // 単体テスト時にエラーが発生るので型チェックが必要
      if (typeof localized === 'string' && category + '.' + id !== localized) {
        return localized;
      }
    }
    logger.warn(`Undefined localize ID: ${JSON.stringify(ids)}`);
    return defaultValue;
  }
}

class StringCategory<T extends string> extends Category<T, string> {
  constructor(
    namespace: typeof NAMESPACE[keyof typeof NAMESPACE],
    t: TranslationFuncType,
    convertKeyFunc: (key: T) => Optional<T> = (k) => k,
    defaultKey: Optional<T> = undefined
  ) {
    super(namespace, t, convertKeyFunc, defaultKey, (ids) => ids[0] ?? '');
  }
}

class StringKanaCategory extends StringCategory<string> {
  constructor(
    namespace: typeof NAMESPACE[keyof typeof NAMESPACE],
    t: TranslationFuncType,
    convertKeyFunc: (key: string) => Optional<string>
  ) {
    super(namespace, t, (key) =>
      returnIf(convertKeyFunc(key), (value) => value + '-kana')
    );
  }

  override localize<U extends string | Optional<string>>(
    idOrIds: U | U[],
    key?: Optional<string>,
    options?: string | TOptions<StringMap> | undefined
  ): Exclude<U, undefined> | string {
    const value = super.localize(idOrIds, key, options);
    if (typeof value === 'string') {
      return normalizeKana(value);
    } else {
      return value;
    }
  }
}

const URL_KEY_NAMES = ['official', 'youtube'] as const;
type UrlKeyNameType = typeof URL_KEY_NAMES[number];

// 関連URLを返す。種類とURLの組の配列の形で返す
class StringUrlCategory {
  private readonly categories: Record<
    UrlKeyNameType,
    Category<string, Optional<string>>
  >;
  constructor(
    namespace: typeof NAMESPACE[keyof typeof NAMESPACE],
    t: TranslationFuncType
  ) {
    this.categories = {} as Record<
      UrlKeyNameType,
      Category<string, Optional<string>>
    >;
    for (const name of URL_KEY_NAMES) {
      // 定義された種類に対してカテゴリを生成する
      this.categories[name] = new Category(
        namespace,
        t,
        (key) => key + '-' + name,
        undefined,
        () => undefined
      );
    }
  }

  localize<U extends string | Optional<string>>(
    idOrIds: U | U[],
    key?: Optional<string>,
    options?: string | TOptions<StringMap> | undefined
  ): { name: UrlKeyNameType; url: string }[] {
    return URL_KEY_NAMES.reduce<{ name: UrlKeyNameType; url: string }[]>(
      (localizedArray, name) => {
        const category = this.categories[name];
        const localized = category?.localize(idOrIds, key, options);
        return [
          ...localizedArray,
          ...(defined(localized) ? [{ name: name, url: localized }] : []),
        ];
      },
      []
    );
  }
}

const createI18n = (
  t: TranslationFuncType,
  defaultLabelCategory: LabelCategory
): I18n => {
  return {
    DATA: new StringCategory(
      NAMESPACE.DATA,
      t,
      (key) => LOCALIZE_KEY_NAMES[key]
    ),
    DATA_KANA: new StringKanaCategory(
      NAMESPACE.DATA,
      t,
      (key) => LOCALIZE_KEY_NAMES[key]
    ),
    LABELS: new StringCategory(
      NAMESPACE.LABELS,
      t,
      undefined,
      defaultLabelCategory
    ),
    URLS: new StringUrlCategory(NAMESPACE.URLS, t),
  };
};

export const useI18n = (): I18n => {
  // labelは列タイトルなど、dataは自動生成
  const defaultLabelCategory = LABEL_CATEGORY.COMMON;
  const { t } = useTranslation(Object.values(NAMESPACE));
  return createI18n(t, defaultLabelCategory);
};
