import {
  Box,
  Button,
  ButtonProps,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MenuViewData } from '../../features/ViewModel';
import { useI18n } from '../../hooks/I18n';
import { defined } from '../../utils/Util';

type HeaderMenuProps = {
  elements: React.ReactElement[];
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ elements }) => {
  const columnCount = 1;
  const rows = elements.reduce((rows, element) => {
    const lastRow = rows[rows.length - 1];
    if (defined(lastRow) && lastRow.length < columnCount) {
      return [...rows.slice(0, rows.length - 1), [...lastRow, element]];
    } else {
      return [...rows, [element]];
    }
  }, [] as React.ReactElement[][]);
  return (
    <table>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map((element, index) => (
              <td key={index}>{element}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DropDown = styled('div')({
  display: 'inline-block',
  // 通常は隠す。メニューをボタンの外に出すためabsolute指定
  '& .content': {
    display: 'none',
    position: 'absolute',
  },
  // 親要素がhoverされたら表示
  '&:hover .content': {
    display: 'block',
  },
});

const InnerButton: React.FC<
  ButtonProps & {
    buttonId: string;
  }
> = ({ buttonId, ...props }) => {
  const i18n = useI18n();
  return (
    <Button
      {...props}
      variant={'contained'}
      disableElevation
      sx={{ ...props.sx, fontSize: '1.125rem' }}
    >
      <Typography>{i18n.LABELS.localize(buttonId)}</Typography>
    </Button>
  );
};

type HeaderButtonProps = ButtonProps & {
  buttonId: string;
  items: MenuViewData;
};

const HeaderButton: React.FC<HeaderButtonProps> = ({
  buttonId,
  items,
  ...props
}) => {
  const i18n = useI18n();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  if (typeof items === 'string') {
    // Aboutページなどサブメニューがないもの
    return (
      <InnerButton
        {...props}
        buttonId={buttonId}
        onClick={() => history.push(items)}
      />
    );
  } else {
    return (
      <DropDown>
        <InnerButton {...props} buttonId={buttonId} />
        <Paper
          ref={elementRef}
          className="content"
          sx={{ boxShadow: 1, p: 1 }}
          onClick={() => {
            // クリック後にメニューを消す
            // noneにして画面更新した後、もとに戻す
            const element = elementRef.current;
            if (defined(element)) {
              element.style.display = 'none';
              setTimeout(() => {
                element.style.removeProperty('display');
              });
            }
          }}
        >
          <HeaderMenu
            elements={items.map((item) => (
              <Box key={item.displayId} sx={{ px: 1, py: 0.5 }}>
                <Link to={{ pathname: item.path }}>
                  <Typography variant="body2">
                    {i18n.LABELS.localize(item.displayId)}
                  </Typography>
                </Link>
              </Box>
            ))}
          />
        </Paper>
      </DropDown>
    );
  }
};

export default React.memo(HeaderButton);
