import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import {
  H3,
  RequiredOptionTopTypography,
  StyledTable,
  TableArea,
} from '../../components/ui/StyledComponents';
import { StyledTableCell, TableBase } from '../../components/ui/TableBase';
import { JsonTableData, toData } from '../../features/Model';
import {
  createTableData,
  LinkData,
  RankingColumn,
  RankingColumnId,
  RankingRow,
  RankingTableData,
} from '../../features/ViewModel';
import { useI18n } from '../../hooks/I18n';
import { fetchRaceData, useTableData } from '../../hooks/UseData';

import { TableBody, TableHead, TableRow, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatOrLocalize } from '../../features/Formatter';
import { usePageTitleUpdateEffect } from '../../hooks/PageUtil';
import { TableInstance } from '../../lib/ReactTableWrapper';
import { defined, returnIf } from '../../utils/Util';

const RACE_CATEGORIES = ['gt500', 'gt300'];

type TopPageTableProps = BoxProps & {
  path: string;
  nameColumnId: RankingColumnId;
  targetColumnId: RankingColumnId;
  targetColumnWidth: string;
};

const createTable = (
  reactTable: TableInstance<RankingRow, RankingColumn>,
  targetColumnWidth: string,
  theme: Theme
): React.ReactElement => {
  const { headerGroups, prepareRow, page } = reactTable;
  const widths = ['60px', 'auto', targetColumnWidth];

  return (
    <StyledTable style={{ tableLayout: 'fixed' }}>
      <TableHead>
        {headerGroups.map((headerGroup) => {
          return (
            <TableRow key={headerGroup.getHeaderGroupProps()['key']}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <StyledTableCell
                    key={column.id}
                    header={true}
                    theme={theme}
                    sx={{ width: widths[index] }}
                  >
                    {column.render('Header')}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <TableRow key={row.id}>
              {row.cells.map((cell) => {
                const key = cell.getCellProps()['key'];
                return (
                  <StyledTableCell key={key} theme={theme}>
                    {cell.render('Cell')}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const TopPageTable: React.FC<TopPageTableProps> = ({
  path,
  nameColumnId,
  targetColumnId,
  targetColumnWidth,
  ...props
}) => {
  const { isLoading, data: tableData } = useTableData(
    path,
    undefined,
    (data: JsonTableData) => createTableData(toData(data))
  );

  if (!(tableData instanceof RankingTableData)) {
    return <></>;
  }
  const nameColumn = tableData.getColumn(nameColumnId.getFullId());
  // 現役ボタンを非表示にするためactiveRankは常にfalse
  const rankingColumn = returnIf(
    tableData.getColumn(targetColumnId.getFullId()),
    (column) =>
      new RankingColumn(column.id, column.hasRank(), false, false, false)
  );
  if (!defined(nameColumn) || !defined(rankingColumn)) {
    return <></>;
  }
  let columns: RankingColumn[];
  let rows: RankingRow[];
  if (rankingColumn.hasRank()) {
    // ポイントランキング、通算ランキング
    columns = [nameColumn, rankingColumn];
    const getRank = (row: RankingRow): number => {
      const rank = row.getCell(rankingColumn.id)?.rank?.rank;
      return rank ?? Number.MAX_SAFE_INTEGER;
    };
    rows = tableData
      .getRows()
      .sort((row1, row2) => getRank(row1) - getRank(row2))
      .filter((row) => getRank(row) <= 5);
  } else {
    // 最新レース結果
    columns = [rankingColumn, nameColumn];
    rows = tableData.getRows().slice(0, 5);
  }

  const createTableWithTargetColumnWidth = (
    reactTable: TableInstance<RankingRow, RankingColumn>,
    theme: Theme
  ): React.ReactElement => createTable(reactTable, targetColumnWidth, theme);

  return (
    <>
      <TableBase
        tableData={new RankingTableData(columns, rows)}
        isLoading={isLoading}
        initialState={{}}
        showAggregatorButtons={false}
        createTable={createTableWithTargetColumnWidth}
        {...props}
      />
    </>
  );
};
const TopPageBox: React.FC<{ topTableModel: TopTableModel; category: string }> =
  ({ topTableModel, category }) => {
    const i18n = useI18n();
    const path = topTableModel.getPath(category);
    const link = topTableModel.getLink(category).getLinkData();
    return (
      // marginだと50%を超える
      <Box key={path} sx={{ width: { xs: '100%', sm: '50%' }, px: 1, mb: 1 }}>
        <div style={{ display: 'flex' }}>
          <RequiredOptionTopTypography sx={{ ml: 1, mr: 'auto' }}>
            {category.toUpperCase()}
          </RequiredOptionTopTypography>
          <Typography
            variant="body2"
            component={'span'}
            sx={{ mr: 1, mt: 'auto' }}
          >
            <Link to={link}>{i18n.LABELS.localize('top_read_more')}</Link>
          </Typography>
        </div>
        <TopPageTable
          path={path}
          nameColumnId={topTableModel.nameColumnId}
          targetColumnId={topTableModel.targetColumnId}
          targetColumnWidth={topTableModel.targetColumnWidth}
        />
      </Box>
    );
  };

class TopTableModel {
  readonly targetColumnWidth;
  constructor(
    readonly displayName: string,
    private readonly path: string,
    private readonly hash: string,
    private readonly queries: [string, string][],
    private readonly searchQueries: [string, string][],
    readonly nameColumnId: RankingColumnId,
    readonly targetColumnId: RankingColumnId,
    targetColumnWidth?: string
  ) {
    this.targetColumnWidth = targetColumnWidth ?? '60px';
  }

  getPath(category: string): string {
    const path = this.path + '/' + this.hash;
    const queries = this.queries.concat([['race_category', category]]);
    return (
      [path]
        .concat(queries.map((q) => q[0]))
        .concat(queries.map((q) => q[1]))
        .join('-') + '.json'
    );
  }

  getLink(category: string): LinkData {
    const queries = this.searchQueries.concat([['race_category', category]]);
    const search = queries.map((query) => query[0] + '=' + query[1]).join('&');
    return new LinkData(this.path, search, this.hash);
  }
}

const TopPageMessage: React.FC = () => {
  const i18n = useI18n();
  return (
    <>
      <TableArea title={i18n.LABELS.localize('top_welcome')}>
        {i18n.LABELS.localize('top_welcome_message')
          .split('\n')
          .map((text) => (
            <Typography key={text} variant="body2" sx={{ px: 1 }}>
              {text}
            </Typography>
          ))}
      </TableArea>
    </>
  );
};

const TopPage: React.FC = () => {
  const i18n = useI18n();
  const raceData = fetchRaceData();
  const latestRaceId =
    Object.keys(raceData)
      .map((v) => parseInt(v))
      .sort((a, b) => a - b)
      .map((v) => String(v))
      .slice(-1)[0] ?? '';

  const latestRaceName = formatOrLocalize(
    'race_id',
    latestRaceId,
    i18n
  ).displayName;

  const latestYear = raceData[latestRaceId]?.year ?? '';

  const latestYearName = formatOrLocalize(
    'race_year',
    String(latestYear),
    i18n
  ).displayName;

  const topTablesModel: [string, TopTableModel[]][] = [
    [
      i18n.LABELS.localize('top_latest'),
      [
        new TopTableModel(
          i18n.LABELS.localize('top_latest_race', undefined, {
            race: latestRaceName,
          }),
          `race/race_id/${latestRaceId}`,
          'ranking',
          [['race_kind', 'R']],
          [],
          new RankingColumnId('key_driver_name', 'group_concat'),
          new RankingColumnId('key_position', 'group_concat')
        ),
        new TopTableModel(
          i18n.LABELS.localize('top_latest_driver_point', undefined, {
            year: latestYearName,
          }),
          `year/race_year/${latestYear}`,
          'driver_ranking',
          [],
          [],
          new RankingColumnId('key_driver_name', 'concat_set'),
          new RankingColumnId('key_total_driver_point', 'concat_set')
        ),
      ],
    ],
    [
      i18n.LABELS.localize('top_ranking'),
      [
        new TopTableModel(
          i18n.LABELS.localize('top_driver_ranking_win'),
          `driver_name_ranking`,
          'table',
          [],
          [['column_id', 'win-sum_and_other-value']],
          new RankingColumnId('key_driver_name', 'concat_set'),
          new RankingColumnId('win', 'sum_and_other')
        ),
        new TopTableModel(
          i18n.LABELS.localize('top_driver_ranking_driver_point'),
          `driver_name_ranking`,
          'table',
          [],
          [['column_id', 'total_driver_point-sum_and_other-value']],
          new RankingColumnId('key_driver_name', 'concat_set'),
          new RankingColumnId('total_driver_point', 'sum_and_other'),
          '120px'
        ),
        new TopTableModel(
          i18n.LABELS.localize('top_manufacturer_ranking_win'),
          `manufacturer_ranking`,
          'table',
          [],
          [['column_id', 'win-sum_and_other-value']],
          new RankingColumnId('key_manufacturer', 'concat_set'),
          new RankingColumnId('win', 'sum_and_other')
        ),
      ],
    ],
  ];

  usePageTitleUpdateEffect(i18n.LABELS.localize('page_title_top'));

  return React.useMemo(
    () => (
      <Box
        sx={{
          display: 'flex', // 横幅はコンテンツに合わせる
          flexDirection: 'row', // 横方向に並べる
          flexWrap: 'wrap', // はみ出したら改行
          width: '100%', // 親の幅と合わせる
          maxWidth: '960px',
          m: 'auto', // 横のセンタリング
        }}
      >
        <TopPageMessage />
        {topTablesModel.map(([displayName, tables]) => (
          <TableArea title={displayName} key={displayName}>
            {tables.map((topTableModel) => (
              <H3
                title={topTableModel.displayName}
                key={topTableModel.displayName}
              >
                {RACE_CATEGORIES.map((category) => (
                  <TopPageBox
                    key={category}
                    topTableModel={topTableModel}
                    category={category}
                  />
                ))}
              </H3>
            ))}
          </TableArea>
        ))}
      </Box>
    ),
    []
  );
};

export default TopPage;
