import { PageState } from './DataUtil';
import { createPageState } from './ViewModel';

export const getHistoryState = (): PageState => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (history.state?.state as PageState) ?? createHistoryState()
  );
};

export const setHistoryState = (state: PageState): void => {
  history.replaceState({ state }, '');
};

const createHistoryState = (): PageState => {
  const location = window.location;
  return (
    createPageState(
      location.hash.replace('#', ''),
      location.search.replace('?', '')
    ) ?? {
      initialTable: undefined,
      tableStates: {},
    }
  );
};
