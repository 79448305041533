import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Theme, Typography } from '@mui/material';
import React from 'react';
import { getIconSize } from '../../utils/Util';

type UrlTableProps = {
  items: {
    displayName: string;
    url: string;
  }[];
  theme: Theme;
};

export const UrlTable: React.FC<UrlTableProps> = ({ items, theme }) => {
  const iconSize = getIconSize(theme.typography.body2.fontSize);

  return (
    <table>
      <tbody>
        {items.reduce<React.ReactElement[]>(
          (elements, { displayName, url }) => {
            return [
              ...elements,
              <tr key={displayName}>
                <td>
                  <Typography variant="body2" sx={{ px: 1 }}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: 'inline-flex' }}
                    >
                      {displayName}
                      <OpenInNewIcon sx={{ fontSize: iconSize }} />
                    </a>
                  </Typography>
                </td>
              </tr>,
            ];
          },
          []
        )}
      </tbody>
    </table>
  );
};
