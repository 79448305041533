import { Box, useTheme } from '@mui/material';
import React from 'react';
import { createRequiredChoiceButtons } from '../../components/layouts/TableSection';
import AchievementPageItem from '../../components/ui/AchievementPageItem';
import { PageArea } from '../../components/ui/StyledComponents';
import { parseTableName, TableSectionState } from '../../features/DataUtil';
import { getHistoryState, setHistoryState } from '../../features/HistoryUtil';
import { I18n, useI18n } from '../../hooks/I18n';
import { usePageTitleUpdateEffect } from '../../hooks/PageUtil';
import { FileState, PathData, useTableData } from '../../hooks/UseData';
import { defined, Optional, setDefault } from '../../utils/Util';

// { gt300: [ {name: win_10, path: "achievement/.../ranking-race_category-gt300.json"} ]
type AchievementPageModel = Record<
  string,
  { name: string; tablePath: string; linkPagePath: string }[]
>;

// 通常columnIdはproducerを含まないが、URLのクエリに入れる場合は含める必要がある
const COLUMN_ID_RACE_DATE_FOR_LINK =
  'achieved_race_key_race_date-concat_value_and_other-value-race_kind_R';

const createLinkPagePath = (
  dirPath: string,
  name: string,
  category: Optional<string>
): string => {
  const query = defined(category) ? `race_category=${category}` : '';
  return `${dirPath}?${query}&column_id=${COLUMN_ID_RACE_DATE_FOR_LINK}&order=desc#${name}`;
};

const createPathDataMap = (paths: PathData): Record<string, string> => {
  let achievement_paths = paths['achievement'];
  if (typeof achievement_paths !== 'object') {
    return {};
  }
  achievement_paths = achievement_paths['achievement'];
  if (typeof achievement_paths !== 'object') {
    return {};
  }
  return Object.entries(achievement_paths).reduce(
    (map, [name, value]): Record<string, string> => {
      if (typeof value === 'string') {
        map[name] = value;
      }
      return map;
    },
    {} as Record<string, string>
  );
};

const createAchievementPageModel = (
  tablesJsonMap: Record<string, FileState<Optional<string[]>>>,
  pathMap: Record<string, string>,
  state: TableSectionState,
  setState: React.Dispatch<React.SetStateAction<TableSectionState>>
): AchievementPageModel => {
  const pageData = Object.entries(tablesJsonMap).reduce((acc, [name, data]) => {
    if (defined(data.data)) {
      for (const tableJsonName of data.data) {
        // ranking-race_category-gt300
        const [tableName, parameters] = parseTableName(tableJsonName);
        const dirPath = pathMap[name];
        if (!defined(dirPath)) {
          continue;
        }
        const category = parameters['race_category'];
        const tableJsonPath = `${dirPath}/${tableJsonName}.json`;
        const linkPagePath = createLinkPagePath(dirPath, tableName, category);
        // key に undefined が使えないので空文字を入れる
        setDefault(acc, category ?? '', []).push({
          name,
          tablePath: tableJsonPath,
          linkPagePath,
        });
      }
    }
    return acc;
  }, {} as AchievementPageModel);

  // 初期値は2個目、つまりGT500
  const defaultRaceCategory =
    Object.keys(pageData)
      .filter((v) => v !== '')
      .sort()[1] ?? '';
  // URLやヒストリに含まれていればそれを使用
  const raceCategory = state.optionsState.race_category ?? defaultRaceCategory;
  setState({
    optionsState: {
      race_category: raceCategory,
    },
    tableState: {},
  });
  return pageData;
};

const createOptionButton = (
  achievementPageModel: Optional<AchievementPageModel>,
  i18n: I18n,
  state: TableSectionState,
  setState: React.Dispatch<React.SetStateAction<TableSectionState>>
): React.ReactElement | React.ReactElement[] => {
  if (!defined(achievementPageModel)) {
    return <></>;
  }
  const categories = Object.keys(achievementPageModel);
  return createRequiredChoiceButtons(
    {
      race_category: new Set(categories.map((v) => (v === '' ? undefined : v))),
    },
    { race_category: categories },
    i18n,
    state,
    setState
  );
};

const createAchievementItems = (
  achievementPageModel: Optional<AchievementPageModel>,
  sm: number,
  state: TableSectionState
) => {
  const category = state.optionsState['race_category'] ?? '';
  const itemsData = achievementPageModel?.[category] ?? [];
  // 横幅がxsの場合は画面幅に合わせて表示、それ以上の場合は複数列で表示
  // 内部で fetchJsonData を呼ぶためロード中は Suspense で非表示にする
  return itemsData.map(({ name, tablePath, linkPagePath }) => (
    <Box key={name} sx={{ width: { xs: '100%', sm } }}>
      <React.Suspense fallback={<></>}>
        <AchievementPageItem
          name={name}
          tablePath={tablePath}
          linkPagePath={linkPagePath}
        ></AchievementPageItem>
      </React.Suspense>
    </Box>
  ));
};

const AchievementPage: React.FC<{ paths: PathData }> = ({ paths }) => {
  const sm = useTheme().breakpoints.values.sm; // 600
  const i18n = useI18n();
  const pageTitle = i18n.LABELS.localize('page_title_achievement');
  usePageTitleUpdateEffect(pageTitle);

  // リンク元が設定したstateよりテーブル名と列名を取得
  const defaultTableState: TableSectionState = getHistoryState().tableStates
    .table ?? {
    optionsState: {},
    tableState: {},
  };
  const [state, setState] = React.useState(defaultTableState);

  // 変更の可能性があれば全て呼ぶ。更新はかからないため
  React.useMemo(() => {
    setHistoryState({ initialTable: undefined, tableStates: { table: state } });
  }, [state]);

  // "win_5" : "achievement/achievement/win_5"
  const pathMap = createPathDataMap(paths);

  const tablesJsonMap: Record<string, FileState<Optional<string[]>>> = {};
  for (const [name, path] of Object.entries(pathMap)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    tablesJsonMap[name] = useTableData(
      path + '/tables.json',
      undefined,
      (data: string[]) => {
        return data;
      }
    );
  }

  const isTablesJsonMapReady = Object.values(tablesJsonMap).every(
    (value) => !value.isLoading
  );
  const achievementPageModel = React.useMemo(() => {
    if (!isTablesJsonMapReady) {
      return undefined;
    } else {
      return createAchievementPageModel(
        tablesJsonMap,
        pathMap,
        state,
        setState
      );
    }
  }, [isTablesJsonMapReady]);

  const optionButton = React.useMemo(
    () => createOptionButton(achievementPageModel, i18n, state, setState),
    [state]
  );

  const achievementItems = React.useMemo(
    () => createAchievementItems(achievementPageModel, sm, state),
    [state]
  );

  return (
    <PageArea title={pageTitle}>
      {optionButton}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap', // はみ出したら改行
        }}
      >
        {achievementItems}
      </Box>
    </PageArea>
  );
};

export default AchievementPage;
