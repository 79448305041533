import { Box, BoxProps } from '@mui/material';
import React, { useState } from 'react';
import {
  Table,
  TableSectionState,
  createTableName,
} from '../../features/DataUtil';
import { useTableData } from '../../hooks/UseData';
import { formatOrLocalize } from '../../features/Formatter';
import { JsonTableData, toData } from '../../features/Model';
import {
  IndividualTableData,
  RankingTableData,
  createTableData,
} from '../../features/ViewModel';
import { I18n, LABEL_CATEGORY, useI18n } from '../../hooks/I18n';
import { getLogger } from '../../utils/Logger';
import {
  Optional,
  defined,
  filterRecords,
  isSameObject,
  mergeRecords,
  setDefault,
} from '../../utils/Util';
import IndividualTable from '../ui/IndividualTable';
import RankingTable from '../ui/RankingTable';
import {
  ActiveOnlyButton,
  FilterButton,
  RequiredOptionTopButton,
} from '../ui/StyledComponents';

const logger = getLogger(import.meta.url);

interface TableSectionProps extends BoxProps {
  filePath: string;
  table: Table;
  initialState: TableSectionState;
  onStateChanged: (newState: TableSectionState) => void;
  onLinkDisplayed: (link: string) => void;
  onReadyToScroll: () => void;
}

const getAvailableItems = (
  currentValue: Record<string, string>,
  table: Table
): Record<string, string[]> => {
  const availableItems = {} as Record<string, string[]>;
  const {
    requiredChoiceOptions,
    requiredToggleOptions,
    optionalOptions,
    data,
  } = table;
  // 必須項目が変更可能か。現在の値を上書きしてチェック
  for (const [optionId, items] of Object.entries(requiredChoiceOptions)) {
    for (const item of items) {
      if (defined(item)) {
        const newValue = { ...currentValue, [optionId]: item };
        if (data.some((value) => isSameObject(value, newValue))) {
          setDefault(availableItems, optionId, []).push(item);
        }
      } else {
        // race_categoryがundefinedのときのための処理
        // 値を削除した組が存在するかチェック
        const newValue = { ...currentValue };
        delete newValue[optionId];
        if (data.some((value) => isSameObject(value, newValue))) {
          setDefault(availableItems, optionId, []).push('');
        }
      }
    }
  }
  // トグルの必須項目が変更可能か。値ありと値なしでチェック
  for (const [optionId, item] of Object.entries(requiredToggleOptions)) {
    const newValue = { ...currentValue, [optionId]: item };
    if (data.some((value) => isSameObject(value, newValue))) {
      setDefault(availableItems, optionId, []).push(item);
    }
    delete newValue[optionId];
    if (data.some((value) => isSameObject(value, newValue))) {
      setDefault(availableItems, optionId, []).push('');
    }
  }

  // 任意項目が変更可能か。
  // 任意項目は排他なのでリセットしたうえで値をセットしてチェック
  const currentValueForOptional = Object.assign({}, currentValue);
  for (const optionId of Object.keys(optionalOptions)) {
    delete currentValueForOptional[optionId];
  }
  for (const [optionId, items] of Object.entries(optionalOptions)) {
    for (const item of items) {
      const newValue = { ...currentValueForOptional, [optionId]: item };
      if (data.some((value) => isSameObject(value, newValue))) {
        setDefault(availableItems, optionId, []).push(item);
      }
    }
  }
  return availableItems;
};

const createFilterOptions = (
  optionalOptions: Record<string, Set<string>>,
  availableItems: Record<string, string[]>,
  i18n: I18n
) => {
  return Object.entries(optionalOptions).map(([optionId, values]) => ({
    id: optionId,
    displayName: i18n.LABELS.localize(optionId),
    values: Array.from(values)
      .map((value) => {
        const { displayName, kana } = formatOrLocalize(optionId, value, i18n);
        const item = availableItems[optionId];
        return {
          id: value,
          displayName,
          available: defined(item) && item.includes(value),
          kana,
        };
      })
      .sort((a, b) => a.kana.localeCompare(b.kana)),
  }));
};

export const createRequiredChoiceButtons = (
  // TODO AchievementPage からも使用している。整理したほうが良い
  requiredChoiceOptions: Record<string, Set<Optional<string>>>,
  availableItems: Record<string, string[]>,
  i18n: I18n,
  state: TableSectionState,
  setState: React.Dispatch<React.SetStateAction<TableSectionState>>
): React.ReactElement[] => {
  return Object.entries(requiredChoiceOptions).map(([optionId, values]) => (
    <RequiredOptionTopButton
      key={optionId}
      values={Array.from(values.values()).map((id) => {
        if (defined(id)) {
          // 値がundefinedでない場合
          return {
            id,
            value: formatOrLocalize(optionId, id, i18n).displayName,
            available: availableItems[optionId]?.includes(id) ?? false,
          };
        } else {
          // 値がundefinedの場合、IDは空文字
          // 表示文字列はlabelsに記載の固定文字列を使用
          const noValueId = `${optionId}_no_value`;
          return {
            id: '',
            value: i18n.LABELS.localize(noValueId, LABEL_CATEGORY.VALUE),
            available: availableItems[optionId]?.includes('') ?? false,
          };
        }
      })}
      selectedId={state.optionsState[optionId] ?? ''}
      canUnselect={false}
      onChanged={(valueId) => {
        setState((prev) => {
          const optionsState = { ...prev.optionsState };
          // valueIdが空文字の場合もある。GT300/500ではなく「その他」の場合
          optionsState[optionId] = valueId;
          return {
            optionsState,
            tableState: { ...prev.tableState, pageIndexOrRowId: 0 },
          };
        });
      }}
    />
  ));
};

const createRequiredToggleButtons = (
  requiredChoiceOptions: Record<string, string>,
  availableItems: Record<string, string[]>,
  i18n: I18n,
  state: TableSectionState,
  setState: React.Dispatch<React.SetStateAction<TableSectionState>>
): React.ReactElement[] => {
  return Object.entries(requiredChoiceOptions).map(
    ([optionId, availableValue]) => {
      // SUPER GTのみボタン。ActiveOnlyButton のスタイルを使用
      return (
        <ActiveOnlyButton
          key={optionId}
          values={[
            {
              id: optionId,
              // 値にはavailableValue ではなく optionId (sgt) を使用
              value: i18n.LABELS.localize(optionId),
              available:
                availableItems[optionId]?.includes(availableValue) ?? false,
            },
          ]}
          selectedId={
            // state には availableValue がセットされている
            state.optionsState[optionId] === availableValue ? optionId : ''
          }
          canUnselect={availableItems[optionId]?.includes('') ?? false}
          onChanged={(valueId) => {
            setState((prev) => {
              let optionsState;
              if (valueId.length > 0) {
                // チェックされたならstate に availableValue を付加
                // テーブル名解決には availableValue が必要
                optionsState = {
                  ...prev.optionsState,
                  [optionId]: availableValue,
                };
              } else {
                // チェックが外れたならプロパティ自体を削除
                optionsState = Object.assign({}, prev.optionsState);
                delete optionsState[optionId];
              }
              return {
                optionsState,
                tableState: { ...prev.tableState, pageIndexOrRowId: 0 },
              };
            });
          }}
        />
      );
    }
  );
};

const TableSection = ({
  filePath,
  table,
  initialState: { optionsState, tableState },
  onStateChanged,
  onLinkDisplayed,
  onReadyToScroll,
  ...props
}: TableSectionProps): React.ReactElement => {
  const {
    tableId,
    requiredChoiceOptions,
    requiredToggleOptions,
    optionalOptions,
  } = table;
  const i18n = useI18n();
  const initialOptionsState = mergeRecords([
    ...Object.entries(requiredChoiceOptions).map(([key, values]) => {
      // TODO
      const value =
        optionsState[key] ??
        Array.from(values.values())
          // race_categoryはundefinedもあるので除外
          .filter((v) => defined(v))
          // 現時点では QF/R, GT300/GT500 なので2個目をデフォルトにする
          .slice(-1)[0];
      return { [key]: value };
    }),
    ...Object.keys(requiredToggleOptions).map((key) => {
      return key in optionsState ? { [key]: optionsState[key] } : {};
    }),
    ...Object.keys(optionalOptions).map((key) => {
      return key in optionsState ? { [key]: optionsState[key] } : {};
    }),
  ]);

  const [state, setState] = useState({
    optionsState: initialOptionsState,
    tableState,
  } as TableSectionState);

  // 変更の可能性があれば全て呼ぶ。更新はかからないため
  React.useMemo(() => {
    onStateChanged(state);
  }, [state]);

  const availableItems = React.useMemo(
    () => getAvailableItems(state.optionsState, table),
    [state.optionsState]
  );

  const createFilePath = () => {
    for (const map of table.data) {
      if (isSameObject(map, state.optionsState)) {
        return `${filePath}/${createTableName(tableId, map)}.json`;
      }
    }
    return `${filePath}/${tableId}.json`;
  };
  const jsonPath = createFilePath();
  const { isLoading, data: tableData } = useTableData(
    jsonPath,
    undefined,
    (data: JsonTableData) => createTableData(toData(data))
  );

  const requiredChoiceButtons = createRequiredChoiceButtons(
    requiredChoiceOptions,
    availableItems,
    i18n,
    state,
    setState
  );
  const requiredToggleButtons = createRequiredToggleButtons(
    requiredToggleOptions,
    availableItems,
    i18n,
    state,
    setState
  );

  const filterButtons = (
    <>
      {requiredToggleButtons}
      <FilterButton
        defaultName={i18n.LABELS.localize('filter_button')}
        values={createFilterOptions(optionalOptions, availableItems, i18n)}
        selectedId={filterRecords(
          state.optionsState,
          (key) => key in optionalOptions
        )}
        onChanged={(newValue) => {
          setState((prev) => {
            const notOptionalOptionsState = filterRecords(
              prev.optionsState,
              (key) => !(key in optionalOptions)
            );
            return {
              optionsState: { ...notOptionalOptionsState, ...newValue },
              tableState: { ...prev.tableState, pageIndex: 0 },
            };
          });
        }}
      />
    </>
  );

  const tableElement = React.useMemo(() => {
    logger.debug('Create table element.');
    if (tableData instanceof RankingTableData) {
      return (
        <RankingTable
          isLoading={isLoading}
          tableData={tableData}
          initialState={state.tableState}
          buttons={filterButtons}
          onStateChanged={(newValue) => {
            setState((prev) => ({ ...prev, tableState: newValue }));
          }}
          onLinkDisplayed={onLinkDisplayed}
          onReadyToScroll={onReadyToScroll}
        />
      );
    } else if (tableData instanceof IndividualTableData) {
      return (
        <IndividualTable
          isLoading={isLoading}
          tableData={tableData}
          buttons={filterButtons}
          onLinkDisplayed={onLinkDisplayed}
          onReadyToScroll={onReadyToScroll}
        />
      );
    } else {
      return <></>;
    }
  }, [isLoading, tableData]);

  return (
    <Box {...props}>
      {requiredChoiceButtons}
      {tableElement}
    </Box>
  );
};

export default TableSection;
