import { I18n } from '../hooks/I18n';
import { defined, Optional } from '../utils/Util';
import { formatOrLocalize } from './Formatter';

const SEP = '/';

export class PathData {
  readonly dirName: string;
  readonly dirParameters: Record<string, Optional<string>>;
  readonly fileName: Optional<string>;
  readonly fileParameters: Record<string, Optional<string>>;

  constructor(...path: string[]) {
    this.dirName = '';
    this.dirParameters = {};
    this.fileParameters = {};

    const parts = path.flatMap((p) => p.split(SEP)).filter((p) => p !== '');
    const [dirs, file] =
      parts[parts.length - 1]?.includes('.') ?? false
        ? [parts.slice(0, parts.length - 1), parts[parts.length - 1]]
        : [parts];

    if (defined(dirs[0])) {
      this.dirName = dirs[0];
      dirs.shift();
      while (defined(dirs[0])) {
        const key = dirs[0];
        dirs.shift();
        const value = dirs[0];
        dirs.shift();
        this.dirParameters[key] = value;
      }
    }
    if (defined(file)) {
      const parts = file.split('.')[0]?.split('-') ?? [];
      this.fileName = parts.splice(0, 2 - (parts.length % 2)).join('-');
      const halfLength = parts.length / 2;
      for (let i = 0; i < halfLength; i++) {
        const key = parts[i];
        const value = parts[halfLength + i];
        if (defined(key) && defined(value)) {
          this.fileParameters[key] = value;
        }
      }
    }
  }

  localizeDirName(i18n: I18n): { displayName: string; kana: string } {
    const [key, value] =
      Array.from(Object.entries(this.dirParameters))[0] ?? [];
    if (defined(key) && defined(value)) {
      // 年を翻訳するためtdではなくformatterを呼ぶ
      return formatOrLocalize(key, value, i18n);
    } else {
      // リストページ用のタイトルを優先して探す
      const pageTitleId = `page_title_${this.dirName}`;
      let displayName = i18n.LABELS.localize([pageTitleId, this.dirName]);
      if (displayName === pageTitleId) {
        displayName = this.dirName;
      }
      return { displayName, kana: displayName };
    }
  }

  localizeFileName(i18n: I18n): string {
    if (!defined(this.fileName)) {
      return '';
    }
    const displayName = i18n.LABELS.localize(this.fileName);
    if (!defined(displayName)) {
      return '';
    }
    const [key, value] =
      Array.from(Object.entries(this.fileParameters))[0] ?? [];
    if (defined(key) && defined(value)) {
      return displayName + formatOrLocalize(key, value, i18n).displayName;
    } else {
      return displayName;
    }
  }

  getUrlsInfo(i18n: I18n): {
    displayName: string;
    url: string;
  }[] {
    const [category, id] =
      Array.from(Object.entries(this.dirParameters))[0] ?? [];
    const localized = i18n.URLS.localize(id, category);

    const urlsInfo = localized.map((value) => ({
      displayName: i18n.LABELS.localize(`page_url_${value.name}`),
      url: value.url,
    }));

    const googleUrlInfo = this.getGoogleUrlInfo(i18n);
    if (defined(googleUrlInfo)) {
      urlsInfo.push(googleUrlInfo);
    }
    return urlsInfo;
  }

  private getGoogleUrlInfo(i18n: I18n): Optional<{
    displayName: string;
    url: string;
  }> {
    const [key] = Array.from(Object.entries(this.dirParameters))[0] ?? [];
    if (defined(key)) {
      let localizedName = this.localizeDirName(i18n).displayName;
      if (['race_year', 'race_id'].includes(key)) {
        // TODO
        const name =
          parseInt(localizedName.slice(0, 4)) < 2005 ? 'JGTC' : 'SUPER GT';
        localizedName = `${name} ${localizedName}`;
      }
      return {
        displayName: i18n.LABELS.localize('page_url_google', undefined, {
          keyword: localizedName,
        }),
        url: encodeURI(`https://www.google.com/search?q=${localizedName}`),
      };
    } else {
      return undefined;
    }
  }
}
