import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

void i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: (_languages, namespaces) => {
        if (namespaces.indexOf('data') !== -1) {
          return '/json/locales/{{lng}}/{{ns}}.json';
        } else if (namespaces.indexOf('urls') !== -1) {
          return '/json/{{ns}}/{{lng}}/data.json';
        } else {
          return '/locales/{{lng}}/{{ns}}.json';
        }
      },
    },
    debug: false,
    lng: 'ja',
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
