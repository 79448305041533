import { I18n, LABEL_CATEGORY } from '../hooks/I18n';
import { Optional, defined, keys, optional, returnIf } from '../utils/Util';
import { PageState, TableSectionState } from './DataUtil';
import { ValueFormatter, getFormatter } from './Formatter';
import { CellData, CellValue, TableData } from './Model';

export type MenuViewData = string | { displayId: string; path: string }[];

const AGGREGATORS_DEFINED = [
  'sum_and_other',
  'cont_and_other',
  'average_and_total',
  'sum_and_first_other',
] as const;
const AGGREGATOR_OTHERS = 'aggregator_others';

export class RankingTableData {
  constructor(readonly columns: RankingColumn[], readonly rows: RankingRow[]) {}

  getColumns(): RankingColumn[] {
    return [...this.columns];
  }

  getColumn(id: string): Optional<RankingColumn> {
    return this.columns.filter((c) => c.id.getFullId() === id)[0];
  }

  getRows(): RankingRow[] {
    return [...this.rows];
  }

  hasRank(): boolean {
    return this.columns.some((c) => c.hasRank());
  }

  hasActiveRank(): boolean {
    let hasActiveRank = false;
    for (const column of this.columns) {
      // activeRankしかない列は想定外
      // rankがあるのにactiveRankがない列があれば非対応
      if (column.hasRank() !== column.hasActiveRank()) {
        return false;
      }
      if (column.hasActiveRank()) {
        // rankもactiveRankも両方あればtrue
        hasActiveRank = true;
      }
    }
    return hasActiveRank;
  }

  getRankingColumnCategories(): string[] {
    //rankがtrueの列のカテゴリを返す
    const categories = Array.from(
      new Set(
        this.columns.filter((c) => c.hasRank()).map((c) => c.id.getCategory())
      )
    );
    if (categories.length === 1 && categories[0] === AGGREGATOR_OTHERS) {
      //otherのみの場合は0とみなす。年のポイントランキングのテーブル、achievementのテーブル
      return [];
    } else {
      return [...AGGREGATORS_DEFINED, AGGREGATOR_OTHERS].filter((c) =>
        categories.includes(c)
      );
    }
  }
}

export class RankingColumn {
  constructor(
    readonly id: RankingColumnId,
    private readonly rank: boolean,
    private readonly activeRank: boolean,
    private readonly additionalInfo: boolean,
    private readonly link: boolean
  ) {}

  canSort(): boolean {
    return this.rank || this.activeRank;
  }

  hasAdditionalInfo(): boolean {
    return this.additionalInfo;
  }

  hasLink(): boolean {
    return this.link;
  }

  hasRank(): boolean {
    return this.rank;
  }

  hasActiveRank(): boolean {
    return this.activeRank;
  }
}

export class RankingColumnId {
  constructor(
    private readonly calculator: string,
    private readonly aggregator: string,
    private readonly extra?: string
  ) {}

  getFullId(): string {
    if (defined(this.extra)) {
      return this.calculator + '-' + this.aggregator + '-' + this.extra;
    } else {
      return this.calculator + '-' + this.aggregator;
    }
  }

  getCategory(): string {
    // 完走時平均順位はothersに表示するため
    return !this.calculator.startsWith('key_') &&
      AGGREGATORS_DEFINED.some((c) => c === this.aggregator)
      ? this.aggregator
      : AGGREGATOR_OTHERS;
  }

  localize(i18n: I18n): string {
    return (
      i18n.LABELS.localize([this.getFullId()], LABEL_CATEGORY.RANKING) ??
      this.getFullId()
    );
  }

  getFormatterId = (): string => {
    let calculator = this.calculator;
    const aggregator = this.aggregator;

    const m0 = /^(achieved_race_).*$/.exec(calculator);
    if (defined(m0) && defined(m0[1])) {
      // achieved_race_key_driver_name-group_concat -> key_driver_name
      calculator = calculator.replace(m0[1], '');
    }

    // 順位などkeyは、連続でも平均でもkeyに従う
    // key_position-avg-value -> position
    // key_position-cont-value -> position
    // keywin_driver_name-concat_set -> driver_name
    const m = /(key.*?_)(.+)/.exec(calculator);
    if (defined(m) && defined(m[2])) {
      // key_position-race_kind_R
      let keyId = m[2];
      if (keyId === 'position') {
        if (!aggregator.startsWith('average')) {
          // リタイアは -1 で示すため、-1のときは空欄で表示するフォーマッターを使用する
          // key_position-group_concat: レース結果
          // key_position-race_kind_R-round_1-best_position_and_other: 個人のレース結果
          return 'position';
        } else {
          // 平均なのでマイナスを取りうる。
          // key_position-race_kind_R-average_and_total
          return 'position_avg';
        }
      } else if (keyId === 'race_id' && aggregator.startsWith('count')) {
        // achieved_race_key_race_id-count_and_other
        return 'race_count';
      } else {
        if (keyId.endsWith('_double')) {
          // finished_double-average_and_total など
          // _double の有無で表示方法は変わらないため外す
          keyId = keyId.substring(0, keyId.length - '_double'.length);
        }
        return keyId;
      }
    }

    // ランキング項目の平均はパーセント表記
    // podium-avg-value -> avg
    // average, average_and_totalがあれば平均とみなす
    if (aggregator.startsWith('average')) {
      return 'avg';
    }
    if (aggregator.startsWith('cont')) {
      return 'cont';
    }
    // 勝利or表彰台サーキット数
    // count_and_other自体は汎用的だが現在この用途のみ
    if (aggregator === 'count_and_other') {
      return 'count_circuit';
    }

    // podium-cont-value -> podium
    return calculator;
  };
}

export class RankingRow {
  private values: Record<string, [RankingColumnId, RankingCell]>;
  constructor(readonly id: string, values: [RankingColumnId, RankingCell][]) {
    this.values = {};
    values.forEach(([columnId, cell]) => {
      this.values[columnId.getFullId()] = [columnId, cell];
    });
  }

  getCell(columnId: RankingColumnId | string): Optional<RankingCell> {
    const id =
      columnId instanceof RankingColumnId ? columnId.getFullId() : columnId;
    return this.values[id]?.[1];
  }

  isActive(): boolean {
    // activeRankがnullならランキングを持たない列
    return !Object.values(this.values).some(
      ([, cell]) => defined(cell.rank) && !defined(cell.activeRank)
    );
  }

  compareTo(
    other: RankingRow,
    columnId: RankingColumnId,
    isDesc: Optional<boolean>
  ): number {
    // 値のないセルは、昇順でも降順でも一番最後に表示する
    const noValue =
      isDesc ?? true ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
    const toValue = (row: RankingRow) =>
      row.getCell(columnId)?.rank?.rank ?? noValue;
    return toValue(this) - toValue(other);
  }

  canAbbreviateRoundInfo(columnId: RankingColumnId): boolean {
    // valueのラウンドが全て同じなら、最初の1個を除いて表示しない
    const targetIds = Object.values(this.values)
      .filter(([, cell]) => {
        const racesSet = new Set(
          cell.getValues().map((value) => value.getRaces())
        );
        return racesSet.size === 1;
      })
      .map((v) => v[0].getFullId());
    return targetIds.slice(1).includes(columnId.getFullId());
  }
}

export class RankingCell {
  constructor(
    private readonly values: RankingValue[],
    readonly rank?: Optional<Rank>,
    readonly activeRank?: Optional<Rank>
  ) {}

  getValues(): RankingValue[] {
    return [...this.values];
  }
}

export class RankingValue {
  private readonly link: Optional<string>;
  private readonly races: Optional<string>;
  private readonly diff: Optional<string>;
  private readonly ratio: Optional<{ top: number; bottom: number }>;

  private readonly rawValue: string;
  constructor(
    tableValue: CellValue,
    private readonly formatter: Optional<ValueFormatter>
  ) {
    this.link = tableValue.link;
    this.diff = tableValue.diff;

    // average_and_totalの場合、達成数とレース数がn/mの形で
    // racesに設定されている。これはDrawerでリンク表示するのではなく
    // テーブルに表示する。
    const races = tableValue.races;
    if (defined(races) && /^[0-9]+\/[0-9]+$/.exec(races)) {
      const [top, bottom] = races.split('/').map((v) => parseInt(v));
      if (defined(top) && defined(bottom)) {
        this.ratio = { top, bottom };
      }
    } else {
      this.races = races;
    }

    this.rawValue = tableValue.value;
  }

  getLocalizedValue(i18n: I18n): string {
    return this.formatter?.format(this.rawValue, i18n) ?? this.rawValue;
  }

  hasFormatter(): boolean {
    return defined(this.formatter);
  }

  getLink(): Optional<LinkData> {
    return optional(this.link, (link) => {
      const l = link.replace(/\\/g, '/').replace(/\.\.\//g, '');
      const [pathname, search, hash] = l.split(/[#?]/);
      if (defined(pathname)) {
        return new LinkData(pathname, search, hash);
      } else {
        return new LinkData(l, search, hash);
      }
    });
  }

  getRaces(): Optional<string> {
    return this.races;
  }

  getDiff(i18n: I18n): Optional<string> {
    const diff = this.diff;
    if (!defined(diff) || !defined(this.formatter)) {
      return diff;
    }
    // New item
    if (diff === 'N') {
      return 'New';
    }
    const num = this.formatter.asNumber(diff);
    if (num === 0) {
      return '';
    }
    const result = this.formatter.format(diff, i18n);
    if (num > 0) {
      return '+' + result;
    } else {
      return result;
    }
  }

  getRatio(): Optional<{ top: number; bottom: number }> {
    return this.ratio;
  }
}

export class Rank {
  readonly rank: number;
  readonly diff: number;
  readonly formatter: Optional<ValueFormatter>;
  constructor(tableValue: CellValue) {
    // 空文字ならNaN
    this.rank = parseInt(tableValue.value);
    this.diff = parseInt(tableValue.diff ?? '');
    this.formatter = getFormatter('rank');
  }

  hasValue(): boolean {
    return isNaN(this.rank);
  }

  getLocalizedValue(i18n: I18n): string {
    const rankString = `${this.rank}`;
    return this.formatter?.format(rankString, i18n) ?? rankString;
  }
}

export class LinkData {
  constructor(
    private readonly pathname: string,
    private readonly search: Optional<string>,
    private readonly hash: Optional<string>
  ) {}

  getLinkData(): {
    pathname: string;
    search?: Optional<string>;
    hash?: Optional<string>;
  } {
    return {
      pathname: '/' + this.pathname,
      search: returnIf(this.search, (s) => '?' + s),
      hash: returnIf(this.hash, (h) => '#' + h),
    };
  }
}

export const createPageState = (hash?: string, search?: string): PageState => {
  const optionsState: Record<string, string> = {};
  let primaryColumnId: Optional<string>;
  let primaryColumnSortByDesc: Optional<boolean>;
  let pageIndexOrRowId: Optional<string>;
  let visibleRowIdPattern: Optional<string>;

  if (defined(search)) {
    for (const query of search.split('&')) {
      const [key, value] = query.split('=');
      if (!defined(key) || !defined(value)) {
        continue;
      }
      if (key === 'row_id') {
        pageIndexOrRowId = value;
      } else if (key === 'column_id') {
        // マージするのはViewModelなので、本来はViewModel生成時に
        // -value を取り除いておくべき
        const [columnId] = parseRankingColumnId(value);
        primaryColumnId = columnId.getFullId();
      } else if (key === 'order') {
        let desc = undefined;
        if (value === 'desc') {
          desc = true;
        } else if (value === 'asc') {
          desc = false;
        }
        if (defined(desc)) {
          primaryColumnSortByDesc = desc;
        }
      } else if (key === 'visible_row_ids') {
        visibleRowIdPattern = value;
      } else {
        optionsState[key] = value;
      }
    }
  }
  const tableSectionState = {
    optionsState,
    tableState: {
      pageIndexOrRowId,
      visibleRowIdPattern,
    },
  } as TableSectionState;
  if (defined(primaryColumnId)) {
    // orderが指定されているがcolumnIdが指定されていない場合は無視する
    if (!defined(primaryColumnSortByDesc)) {
      // desc の指定が無ければ desc = false
      primaryColumnSortByDesc = false;
    }
    tableSectionState.tableState.primaryColumn = {
      columnId: primaryColumnId,
      desc: primaryColumnSortByDesc,
    };
  }

  const tableId = hash ?? '';
  return {
    initialTable: tableId,
    tableStates: { [tableId]: tableSectionState },
  };
};

export class IndividualTableData {
  constructor(
    private readonly columnIds: IndividualColumnId[],
    readonly rows: IndividualRow[]
  ) {}

  getColumnIds(): IndividualColumnId[] {
    return [...this.columnIds];
  }

  hasRank(): boolean {
    const ids = this.getColumnIds();
    return (
      ids.includes(INDIVIDUAL_COLUMN_ID.RANK) &&
      ids.includes(INDIVIDUAL_COLUMN_ID.ACTIVE_RANK)
    );
  }
}

export const INDIVIDUAL_COLUMN_ID = {
  NAME: 'name',
  VALUE: 'value',
  RANK: 'rank',
  ACTIVE_RANK: 'active_rank',
} as const;

export const INDIVIDUAL_COLUMN_IDS = Object.values(INDIVIDUAL_COLUMN_ID);

export type IndividualColumnId =
  typeof INDIVIDUAL_COLUMN_ID[keyof typeof INDIVIDUAL_COLUMN_ID];

export class IndividualRow {
  constructor(
    readonly id: RankingColumnId,
    readonly name: RankingValue,
    readonly cell: RankingCell
  ) {}
}

type Producer = {
  value?: number;
  rank?: number;
  active_rank?: number;
};

const parseRankingColumnId = (
  columnId: string
): [RankingColumnId, keyof Producer] => {
  const parts = columnId.split('-');
  // entry-sum_and_other
  // entry-sum_and_other-value
  // key_position-sum_and_others-value-race_kind_R
  const [calculator, aggregator, producer, ...rest] = parts;

  // 余計なデータはextraに付与する
  // key_position-best_position_and_other-value-race_kind_R-round_1
  const extra = rest.length > 0 ? rest.join('-') : undefined;

  // 長さ3以上であることの確認
  if (!defined(calculator) || !defined(aggregator) || !defined(producer)) {
    // TODO
    throw new Error(`Unexpected format: ${columnId}`);
  }
  const rankingColumnId = new RankingColumnId(calculator, aggregator, extra);
  return [rankingColumnId, producer as keyof Producer];
};

const parseIndividualColumnId = (columnId: string): RankingColumnId => {
  const parts = columnId.split('-');
  // fastestのテーブルではproducerが無くextraが存在する
  // key_fastest_driver-one-gt500
  const [calculator, aggregator, extra, ...rest] = parts;
  // 長さ2以上であることの確認
  // 余計なデータがないことの確認
  if (!defined(calculator) || !defined(aggregator) || rest.length > 0) {
    // TODO
    throw new Error(`Unexpected format: ${columnId}`);
  }
  return new RankingColumnId(calculator, aggregator, extra);
};

export const createTableData = (
  tableData: TableData
): Optional<RankingTableData | IndividualTableData> =>
  returnIf(tableData.columns.length > 0, () =>
    tableData.columns[0]?.title === 'name'
      ? createIndividualTableData(tableData)
      : createRankingTableData(tableData)
  );

const convertColumnIdForPointRank = (
  columnIds: string[],
  columnId: string
): string => {
  // 年間のポイントランキングでtotal_driver_point_rankのvalueを
  // total_driver_pointのrankとして設定し、ソート可能にするための列ID変換
  const m = /^(.*)_point_rank-([a-zA-Z0-9_]+)-value$/.exec(columnId);
  if (defined(m) && defined(m[1]) && defined(m[2])) {
    const title_prefix = `${m[1]}_point-${m[2]}`;
    if (
      columnIds.filter(
        (c) => c === `${title_prefix}-${INDIVIDUAL_COLUMN_ID.VALUE}`
      ).length > 0
    ) {
      return `${title_prefix}-${INDIVIDUAL_COLUMN_ID.RANK}`;
    }
  }
  return columnId;
};

export const createRankingTableData = ({
  columns: tableColumns,
  rows: tableRows,
}: TableData): RankingTableData => {
  const map: Record<string, [RankingColumnId, Producer]> = {};
  for (const [index, tableColumn] of tableColumns.entries()) {
    const title = convertColumnIdForPointRank(
      tableColumns.map((c) => c.title),
      tableColumn.title
    );
    const [columnId, producerId] = parseRankingColumnId(title);
    const producer: Producer = map[columnId.getFullId()]?.[1] ?? {};
    if (!(columnId.getFullId() in map)) {
      map[columnId.getFullId()] = [columnId, producer];
    }
    producer[producerId] = index;
  }

  const columnWithSomeValues = new Set<string>();
  const columnsWithSomeAdditionalInfo = new Set<string>();
  const columnsWithSomeLink = new Set<string>();

  const rows = tableRows.map((tableRow) => {
    const cells = Object.values(map).reduce((cells, [columnId, producer]) => {
      if (!defined(producer.value)) {
        return cells;
      }
      const cellData = tableRow.cells[producer.value];
      const formatter = getFormatter(columnId.getFormatterId());
      if (isIgnoredCell(cellData, formatter)) {
        return cells;
      }
      const values =
        cellData?.values.map((value) => new RankingValue(value, formatter)) ??
        [];
      const toRank = (r: Optional<number>) =>
        optional(r, (v) =>
          optional(tableRow.cells[v]?.values[0], (v) => new Rank(v))
        );
      // ランキング対象外の場合はundefined
      const rank = toRank(producer.rank);
      // 現役対象外の場合もundefined
      const activeRank = toRank(producer.active_rank);
      cells.push([columnId, new RankingCell(values, rank, activeRank)]);
      return cells;
    }, [] as [RankingColumnId, RankingCell][]);

    // 値を含む列を記憶する
    for (const cell of cells) {
      columnWithSomeValues.add(cell[0].getFullId());
      if (cell[1].getValues().filter((v) => defined(v.getRaces())).length > 0) {
        columnsWithSomeAdditionalInfo.add(cell[0].getFullId());
      }
      if (cell[1].getValues().filter((v) => defined(v.getLink())).length > 0) {
        columnsWithSomeLink.add(cell[0].getFullId());
      }
    }
    return new RankingRow(tableRow.id, cells);
  });

  const columns = Object.values(map)
    .filter(([, producer]) => defined(producer.value))
    // 一つも値がない列は除外する
    .filter(([columnId]) => columnWithSomeValues.has(columnId.getFullId()))
    .map(([columnId, producer]) => {
      return new RankingColumn(
        columnId,
        defined(producer.rank),
        defined(producer.active_rank),
        columnsWithSomeAdditionalInfo.has(columnId.getFullId()),
        columnsWithSomeLink.has(columnId.getFullId())
      );
    });
  return new RankingTableData(columns, rows);
};

export const createIndividualTableData = ({
  columns: tableColumns,
  rows: tableRows,
}: TableData): IndividualTableData => {
  const map = tableColumns.reduce((ids, column, index) => {
    const id = INDIVIDUAL_COLUMN_IDS.filter((i) => i === column.title)[0];
    return defined(id) ? { ...ids, [id]: index } : ids;
  }, {} as Record<IndividualColumnId, number>);

  const rows = tableRows.reduce((rows, tableRow) => {
    const name = returnIf(map.name, (v) => tableRow.cells[v]?.values[0]);
    const value = returnIf(map.value, (v) => tableRow.cells[v]);
    const rank = returnIf(map.rank, (v) => tableRow.cells[v]?.values[0]);
    const activeRank = returnIf(
      map.active_rank,
      (v) => tableRow.cells[v]?.values[0]
    );
    if (!name || !value) {
      return rows;
    }

    const columnId = parseIndividualColumnId(name.value);
    const formatter = getFormatter(columnId.getFormatterId());
    if (isIgnoredCell(value, formatter)) {
      return rows;
    }

    const valueCell = new RankingCell(
      value.values.map((v) => new RankingValue(v, formatter)),
      returnIf(rank, (r) => new Rank(r)),
      returnIf(activeRank, (r) => new Rank(r))
    );
    const nameCellValue = new RankingValue(
      name, // calculator + aggregator
      labelFormatter
    );
    return [...rows, new IndividualRow(columnId, nameCellValue, valueCell)];
  }, [] as IndividualRow[]);
  return new IndividualTableData(keys(map), rows);
};

const isIgnoredCell = (
  cellData: Optional<CellData>,
  formatter: Optional<ValueFormatter>
): boolean => {
  // 値が存在しないセルは無視する
  if (!defined(cellData) || !defined(cellData.values[0])) {
    return true;
  } else {
    if (cellData.values.length === 1) {
      // 車番が0の場合は無視してはいけない
      // Formatterを使用して区別する
      return formatter?.asNumber(cellData.values[0]?.value) === 0;
    } else {
      return false;
    }
  }
};

const labelFormatter: ValueFormatter = {
  format: (value: string, i18n: I18n): string => {
    const columnId = parseIndividualColumnId(value);
    return columnId.localize(i18n);
  },
  kana: () => ({}),
  asNumber: (value) => Number(value),
  supportsKana: () => false,
};
