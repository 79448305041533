import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import * as History from 'history';
import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import Header from '../components/layouts/Header';
import StyledComponents, {
  ScrollTopFab,
} from '../components/ui/StyledComponents';
import {
  ActiveOnlyContextProvider,
  ShowAdditionalInfoContextProvider,
} from '../hooks/Context';
import { fetchPathData, fetchRedirectData } from '../hooks/UseData';
import { SendAnalyticsData } from '../lib/AnalyticsUtil';
import AboutPage from './routes/AboutPage';
import AchievementPage from './routes/AchievementPage';
import Container from './routes/Container';
import TopPage from './routes/TopPage';

const ScrollToTop: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    const unregister = history.listen(
      (_location: History.Location, action: History.Action) => {
        // ブラウザの戻ると進むはPOPイベントになるので無視する
        if (action !== 'POP') {
          window.scrollTo(0, 0);
        }
      }
    );
    return () => {
      unregister();
    };
  }, []);
  return <></>;
};

const Default = (): React.ReactElement => {
  const paths = fetchPathData();
  const redirectPaths = fetchRedirectData();
  const routes: React.ReactElement[] = Object.entries(redirectPaths).map(
    ([key, value]) => {
      key = '/' + key;
      value = '/' + value;
      return (
        <Route
          key={key}
          path={key}
          render={() => {
            return <Redirect to={value} />;
          }}
        />
      );
    }
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <BrowserRouter>
        <SendAnalyticsData />
        <ScrollToTop />
        <Header />
        <Box
          component="main"
          sx={{
            mt: 10, // 上からのマージン
            mb: 8, // 下からのマージン
            // mx: 2, // ランキングページのボタンが見切れるためコメントアウト
            width: '100%',
            overflowX: 'hidden', // テーブルスクロールさせる
          }}
        >
          <Switch>
            <Route
              path={'/preview'}
              render={
                // コンポーネントの見た目を確認するためのパス
                () => <StyledComponents />
              }
            />
            <Route
              exact
              path={'/achievement'}
              render={() => <AchievementPage paths={paths} />}
            />
            <Route path={'/about'} render={() => <AboutPage />} />
            <Route exact path={'/'} render={() => <TopPage />} />
            {
              // 上記のどれも合致しない場合は下が実行される
            }
            <ActiveOnlyContextProvider>
              <ShowAdditionalInfoContextProvider>
                <Container parentPath="/" paths={paths} />
              </ShowAdditionalInfoContextProvider>
            </ActiveOnlyContextProvider>
          </Switch>
          <ScrollTopFab />
        </Box>
        {routes}
      </BrowserRouter>
    </Box>
  );
};

export default Default;
