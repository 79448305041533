import { Cancel, Check } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { defined } from '../../utils/Util';

export type OptionalOptions = {
  id: string;
  displayName: string;
  values: { id: string; displayName: string; available: boolean }[];
}[];

export interface OptionalOptionButtonProps extends ButtonProps {
  defaultName: string;
  values: OptionalOptions;
  selectedId: Record<string, string>;
  onChanged?: (selectedId: Record<string, string>) => void;
}

const CANCELLED = -1;

const OptionalOptionButton = ({
  defaultName,
  values,
  selectedId,
  onChanged,
  ...props
}: OptionalOptionButtonProps): React.ReactElement => {
  const [selected, setSelected] = useState(selectedId);
  const [dialogVisible, setDialogVisible] = useState(false);

  const toLabel = (): string => {
    const entries = Object.entries(selected);
    if (!entries[0]) {
      return '';
    }
    const key = entries[0][0];
    const value = entries[0][1];
    const { displayName, values: optionValues } =
      values.filter((v) => v.id === key)[0] ?? {};
    const valueName = optionValues?.filter((v) => v.id === value)[0]
      ?.displayName;
    if (!defined(displayName) || !defined(valueName)) {
      return '';
    }
    return `${displayName} = ${valueName}`;
  };
  const label = toLabel();
  const valueAssigned = label.length > 0;

  const select = (value: Record<string, string>): void => {
    setSelected(value);
    onChanged?.(value);
    setDialogVisible(false);
  };

  return (
    <>
      {values.length > 0 ? (
        <Button
          {...props}
          variant={valueAssigned ? 'contained' : 'outlined'}
          endIcon={
            valueAssigned ? (
              <Cancel
                onClick={(e: React.MouseEvent) => {
                  select({});
                  e.detail = CANCELLED;
                }}
              />
            ) : null
          }
          onClick={(e: React.MouseEvent) => {
            if (e.detail !== CANCELLED) {
              setDialogVisible(true);
            }
          }}
        >
          {valueAssigned ? label : defaultName}
        </Button>
      ) : null}
      <Drawer
        anchor={'bottom'}
        onClose={() => setDialogVisible(false)}
        open={dialogVisible}
        PaperProps={{ sx: { maxHeight: '50%' } }}
      >
        {values.map((option) => (
          <List
            key={option.id}
            subheader={
              <ListSubheader color={'primary'}>
                {option.displayName}
              </ListSubheader>
            }
          >
            {option.values.map((value) => (
              <ListItemButton
                sx={{ pl: 4 }}
                key={value.id}
                selected={selectedId[option.id] === value.id}
                disabled={!value.available}
                onClick={() => select({ [option.id]: value.id })}
              >
                <ListItemText primary={value.displayName} />
                {selectedId[option.id] === value.id ? <Check /> : null}
              </ListItemButton>
            ))}
          </List>
        ))}
      </Drawer>
    </>
  );
};

export default memo(OptionalOptionButton);
