import React from 'react';
import { RaceData } from '../features/DataUtil';
import { JsonTableData } from '../features/Model';
import { MenuViewData } from '../features/ViewModel';
import { defined, Optional } from '../utils/Util';

const DIR_JSON = '/json/';
const FILE_HEADER = '/layouts/header.json';
const DIR_DATA = DIR_JSON + 'data/';
const FILE_PATHS = DIR_DATA + 'paths.json';
const FILE_REDIRECTS = DIR_DATA + 'redirects.json';
const FILE_RACE = DIR_JSON + 'setting/race/race.json';
const FILE_NAME_TABLES = '/tables.json';

export type PathData = { [key: string]: PathData | string };

export type HeaderData = {
  buttons: Record<string, MenuViewData>;
};

export interface FileState<T> {
  isLoading: boolean;
  data: T;
  error?: Error;
}

const dataMap: Map<string, unknown> = new Map();

/* 以下は state を使用してローディング状態を管理 */
const useBaseAsync = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  const cachedData = dataMap.get(path) as Optional<T>;

  const initialState = defined(cachedData)
    ? { data: cachedData, isLoading: false }
    : {
        data: initialValue,
        isLoading: true,
      };
  const [state, setState] = React.useState(initialState as FileState<T>);
  React.useEffect(() => {
    if (defined(cachedData)) {
      // キャッシュを使用する場合はfetchを呼ばない
      if (state.data !== cachedData) {
        // 2回目以降の処理ではinitialStateに代入されないため、setする
        setState({ data: cachedData, isLoading: false });
      }
      return;
    }
    // state.isLoadingは2回目以降の実行だと、この時点でfalseの可能性がある
    // そのためtrueに変更する
    setState((prev) => ({ ...prev, isLoading: true }));
    // isLoadingはthenの中で即時反映されない可能性があるので条件分岐には使用しない
    let mounted = true;
    fetch(path)
      .then((res) => res.json())
      .then(
        (result: V) => {
          if (mounted) {
            const data = map(result);
            setState({ data, isLoading: false });
            dataMap.set(path, data);
          }
        },
        (error: Error) => {
          if (mounted) {
            setState({ data: initialValue, error, isLoading: false });
          }
        }
      );
    return () => {
      mounted = false;
      setState((prev) => ({ ...prev, isLoading: false }));
    };
  }, [path]);
  return state;
};

export const useTableData = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  return useBaseAsync(DIR_DATA + path, initialValue, map);
};

/* 以下は Suspense を使用してローディング状態を管理 */
const fetchJsonData = <T, V = T>(
  path: string,
  map: (data: V) => T = (data) => data as unknown as T
): T => {
  const cachedData = dataMap.get(path) as Optional<T>;
  if (!defined(cachedData)) {
    throw fetch(path)
      .then((res) => res.json())
      .then((data) => dataMap.set(path, map(data)));
  } else {
    return cachedData;
  }
};

export const fetchHeaderData = (): HeaderData => {
  return fetchJsonData(FILE_HEADER);
};

export const fetchPathData = (): PathData => {
  return fetchJsonData(FILE_PATHS);
};

export const fetchRedirectData = (): { [key: string]: string } => {
  return fetchJsonData(FILE_REDIRECTS);
};

export const fetchPageData = (path: string): string[] => {
  return fetchJsonData(DIR_DATA + path + FILE_NAME_TABLES);
};

export const fetchRaceData = (): RaceData => {
  return fetchJsonData<RaceData, JsonTableData>(FILE_RACE, (jsonData) => {
    const raceData: RaceData = {};
    for (const { data } of jsonData.rows) {
      const [raceId, year, round] = data.map((cell) =>
        parseInt(cell[0]?.[0] ?? '')
      );
      if (defined(raceId) && defined(year) && defined(round)) {
        raceData[raceId] = { year, round, stopped: false };
      }
    }
    for (const [raceId, data] of Object.entries(raceData)) {
      const prevData = raceData[parseInt(raceId) - 1];
      if (defined(prevData)) {
        if (prevData.year === data.year && prevData.round + 1 !== data.round) {
          data.stopped = true;
        } else if (prevData.year !== data.year && data.round !== 1) {
          data.stopped = true;
        }
      }
    }
    return raceData;
  });
};
