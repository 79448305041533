import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { H3, PageArea, TableArea } from '../../components/ui/StyledComponents';
import { PathData } from '../../features/PathModel';
import { I18n, useI18n } from '../../hooks/I18n';
import { usePageTitleUpdateEffect } from '../../hooks/PageUtil';
import { getLogger } from '../../utils/Logger';
import { defined } from '../../utils/Util';

const logger = getLogger(import.meta.url);

const initials = 'AGMTあかさたなはまやらわん';

class LocalizedId {
  constructor(readonly id: string, readonly displayName: string) {}
}

class LocalizedIdWithKana extends LocalizedId {
  constructor(id: string, displayName: string, readonly kana: string) {
    super(id, displayName);
  }
}

class LocalizedIdWithPath extends LocalizedId {
  constructor(id: string, displayName: string, readonly path: string) {
    super(id, displayName);
  }
}

class ItemGroup {
  constructor(
    readonly groupId: LocalizedId,
    readonly subGroups: ItemSubGroup[]
  ) {}
}
class ItemSubGroup {
  readonly items: LocalizedIdWithPath[];
  constructor(
    readonly subGroupId: LocalizedId,
    items: LocalizedId[],
    parentPath: string
  ) {
    this.items = items.map((item) => ({ ...item, path: parentPath + item.id }));
  }
}

class InitialModel {
  constructor(
    private readonly initials: string,
    private readonly parentPath: string,
    private readonly paths: string[]
  ) {}

  private createListItems(i18n: I18n): LocalizedIdWithKana[] {
    return (
      this.paths
        .map((path) => {
          const { displayName, kana } = new PathData(
            this.parentPath,
            path
          ).localizeDirName(i18n);
          return new LocalizedIdWithKana(path, displayName, kana);
        })
        // 五十音順にするためソートする
        .sort((a, b) => a.kana.localeCompare(b.kana))
    );
  }

  private createGroupsData(
    listItems: LocalizedIdWithKana[]
  ): [string, string[]][] {
    const initialsOfItems = Array.from(
      new Set(listItems.map((item) => item.kana[0]))
    );
    return [...this.initials].reduce<[string, string[]][]>(
      (groups, groupInitial, index, array) => {
        // 末端の文字に対応するグループは作らない
        const nextInitial = array[index + 1];
        if (!defined(nextInitial)) {
          return groups;
        }

        // グループに含まれるイニシャル
        const rangeSize =
          nextInitial.charCodeAt(0) - groupInitial.charCodeAt(0);
        const subGroupInitials: string[] = [...Array(rangeSize).keys()]
          .map((num) => String.fromCharCode(groupInitial.charCodeAt(0) + num))
          // そのイニシャルで始まる要素がなければ作らない
          .filter((char) => initialsOfItems.includes(char));

        // 空のグループは表示しない
        if (subGroupInitials.length === 0) {
          return groups;
        }
        return [...groups, [groupInitial, subGroupInitials]];
      },
      []
    );
  }

  getInitialGroups(i18n: I18n): ItemGroup[] {
    const listItems = this.createListItems(i18n);
    const groups = this.createGroupsData(listItems);

    const itemGroups: ItemGroup[] = [];
    let otherGroupItems = [...listItems];
    for (const [groupInitial, subGroupInitials] of groups) {
      // IDは文字コードを使う。URLのハッシュになるため
      const groupId = new LocalizedId(
        String(groupInitial.charCodeAt(0)),
        i18n.LABELS.localize('list_index', undefined, {
          initial: groupInitial,
        })
      );
      const itemGroup = new ItemGroup(groupId, []);
      itemGroups.push(itemGroup);
      for (const subGroupInitial of subGroupInitials) {
        const subGroupId = new LocalizedId(
          String(subGroupInitial.charCodeAt(0)),
          subGroupInitial
        );
        const items = listItems.filter((item) =>
          item.kana.startsWith(subGroupInitial)
        );
        // その他のグループに入る要素の候補を更新
        otherGroupItems = otherGroupItems.filter(
          (item) => !items.includes(item)
        );
        itemGroup.subGroups.push(
          new ItemSubGroup(subGroupId, items, this.parentPath)
        );
      }
    }
    if (otherGroupItems.length > 0) {
      itemGroups.push(
        new ItemGroup(
          new LocalizedId('list_others', i18n.LABELS.localize('list_others')),
          [
            new ItemSubGroup(
              new LocalizedId('', ''),
              otherGroupItems,
              this.parentPath
            ),
          ]
        )
      );
    }
    return itemGroups;
  }
}

type IndexTableProps = {
  model: InitialModel;
};

const IndexTable: React.FC<IndexTableProps> = ({ model }) => {
  const i18n = useI18n();
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 1 }}>
      {model.getInitialGroups(i18n).map(({ groupId: { id, displayName } }) => {
        return (
          <Box key={id} sx={{ whiteSpace: 'nowrap', px: 1 }}>
            <HashLink to={`#${id}`}>{displayName}</HashLink>
          </Box>
        );
      })}
    </Box>
  );
};

const StyledGrid: React.FC<{ items: LocalizedIdWithPath[] }> = ({ items }) => (
  <Grid
    container
    columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
    sx={{ p: 3 }}
    rowSpacing={1}
    columnSpacing={1}
  >
    {items.map(({ id, displayName, path }) => (
      <Grid item key={id} xs={2} sm={4} md={4}>
        <Typography variant="body2">
          <Link to={path}>{displayName}</Link>
        </Typography>
      </Grid>
    ))}
  </Grid>
);

const ListPageForFew: React.FC<{ model: InitialModel }> = ({ model }) => {
  const i18n = useI18n();
  const items = model
    .getInitialGroups(i18n)
    .reduce<LocalizedIdWithPath[]>((items, { subGroups }) => {
      const i = subGroups.reduce<LocalizedIdWithPath[]>(
        (items, subGroup) => [...items, ...subGroup.items],
        []
      );
      return [...items, ...i];
    }, []);
  return <StyledGrid items={items} />;
};

const ListPageForMany: React.FC<{ model: InitialModel }> = ({ model }) => {
  const i18n = useI18n();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const hash = window.location.hash.replace('#', '');
  React.useEffect(
    () => elementRef?.current?.scrollIntoView(),
    [elementRef?.current]
  );

  return (
    <>
      <IndexTable model={model} />
      {model.getInitialGroups(i18n).map(({ groupId, subGroups }) => (
        <TableArea
          id={groupId.id}
          key={groupId.id}
          title={groupId.displayName}
          ref={groupId.id === hash ? elementRef : null}
        >
          {subGroups.map(({ subGroupId: { id, displayName }, items }) => {
            const element = <StyledGrid key={id} items={items} />;
            if (id.length > 0) {
              return (
                <H3 key={id} title={displayName}>
                  {element}
                </H3>
              );
            } else {
              return element;
            }
          })}
        </TableArea>
      ))}
    </>
  );
};

type ListPageProps = {
  parentPath: string;
  paths: string[];
};

const ListPage: React.FC<ListPageProps> = ({ parentPath, paths }) => {
  logger.debug('START');
  const i18n = useI18n();
  const pageTitle = new PathData(parentPath).localizeDirName(i18n).displayName;
  usePageTitleUpdateEffect(pageTitle);
  const initialModel = new InitialModel(initials, parentPath, paths);

  return React.useMemo(
    () => (
      <PageArea title={pageTitle}>
        {paths.length > 30 ? (
          <ListPageForMany model={initialModel} />
        ) : (
          <ListPageForFew model={initialModel} />
        )}
      </PageArea>
    ),
    []
  );
};

export default React.memo(ListPage);
