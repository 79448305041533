import { ListItemButton, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import React from 'react';
import { Link } from 'react-router-dom';
import { MenuViewData } from '../../features/ViewModel';
import { I18n, useI18n } from '../../hooks/I18n';
import { getLogger } from '../../utils/Logger';

const logger = getLogger(import.meta.url);

type DrawerLeftProps = {
  buttonsData: Record<string, MenuViewData>;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const DrawerLeft: React.FC<DrawerLeftProps> = ({
  buttonsData,
  visible,
  setVisible,
}) => {
  logger.debug(`START`);
  const i18n = useI18n();

  const hideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setVisible(false);
  };

  const menuData = createMenuTree(buttonsData, i18n);

  const box = (
    <Box onClick={hideDrawer} onKeyDown={hideDrawer}>
      <List>{menuData}</List>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={visible}
        onClose={hideDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {box}
      </Drawer>
    </>
  );
};

type ListItemProps = { displayId: string; path: string };

const ListItem: React.FC<ListItemProps> = ({ displayId, path }) => {
  const i18n = useI18n();
  return (
    <ListItemButton key={displayId} component={Link} to={path}>
      <ListItemText sx={{ pl: 2 }}>
        {i18n.LABELS.localize(displayId)}
      </ListItemText>
    </ListItemButton>
  );
};

const createMenuTree = (
  buttonsData: Record<string, MenuViewData>,
  i18n: I18n
): React.ReactElement[] => {
  return Object.entries(buttonsData).reduce<React.ReactElement[]>(
    (elements, [buttonId, items]) => {
      const listItems =
        typeof items !== 'string' ? (
          items.map(({ displayId, path }) => (
            <ListItem key={displayId} displayId={displayId} path={path} />
          ))
        ) : (
          <ListItem key={buttonId} displayId={buttonId} path={items} />
        );

      return [
        ...elements,
        <List
          key={buttonId}
          subheader={
            <ListSubheader>{i18n.LABELS.localize(buttonId)}</ListSubheader>
          }
        >
          {listItems}
        </List>,
      ];
    },
    []
  );
};

export default DrawerLeft;
