import { Box, Typography } from '@mui/material';
import React from 'react';
import { H3, PageArea, TableArea } from '../../components/ui/StyledComponents';
import { useI18n } from '../../hooks/I18n';
import { usePageTitleUpdateEffect } from '../../hooks/PageUtil';

const AboutPage: React.FC = () => {
  const i18n = useI18n();
  const pageTitle = i18n.LABELS.localize('page_title_about');
  usePageTitleUpdateEffect(pageTitle);

  return React.useMemo(
    () => (
      <Box
        sx={{
          display: 'flex', // 横幅はコンテンツに合わせる
          flexDirection: 'row', // 横方向に並べる
          flexWrap: 'wrap', // はみ出したら改行
          width: '100%', // 親の幅と合わせる
          maxWidth: '960px',
          m: 'auto', // 横のセンタリング
        }}
      >
        <PageArea title={pageTitle}>
          <TableArea title="本サイトについて">
            <Typography variant="body2" sx={{ px: 1 }}>
              SUPER
              GT・JGTCのデータ収集と分析を行う個人サイトです。レース観戦がより面白くなる情報の提供を目指しています。
            </Typography>
          </TableArea>
          <TableArea title="データ収集に関して">
            <Typography component="div" variant="body2" sx={{ px: 1 }}>
              データは公式サイトの情報を参考にしています。下記のデータは未収集のため、ランキングの計算には含まれていません。
              <ul>
                <li>
                  タイヤメーカー
                  <ul>
                    <li>1994年、1995年、1996年の一部、1997年の一部</li>
                  </ul>
                </li>
                <li>
                  監督
                  <ul>
                    <li>
                      1994年～2002年、2003年～2009年の一部、2011年の一部、2014年の一部
                    </li>
                  </ul>
                </li>
                <li>
                  ポールポジション
                  <ul>
                    <li>
                      計11レース (1994年Round1～Round5、1995年Round1～Round6)
                    </li>
                  </ul>
                </li>
                <li>
                  ファステストドライバー
                  <ul>
                    <li>
                      GT500: 計10レース
                      (1996年Round3、1997年Round3、5、1998年Round1～Round3、2000年Round1～Round5)
                    </li>
                    <li>
                      GT300: 計40レース
                      (1994年Round1～1999Round3、Round7、2000年Round1～Round5、Round7、2001年Round3)
                    </li>
                  </ul>
                </li>
              </ul>
              また、車名データの分類は荒く、各車の型番やモデル名は反映しておりません。一部の車は世代によって型番やモデル名が大きく異なるため、型番やモデル名を反映できれば良かったのですが、過去のレースを含めて正確なデータを入手できなかったため、現在の荒い分類にとどまっています。
            </Typography>
          </TableArea>
          <TableArea title="同一データの扱い">
            <Typography component="div" variant="body2" sx={{ px: 1 }}>
              トヨタとレクサスは同一のメーカーとしてランキングを計算しています。また、以下の愛称は下記の選手と同一としてランキングを計算しています。
              <ul>
                <li>
                  Takamori.com
                  <ul>
                    <li>高森 博士 選手</li>
                  </ul>
                </li>
                <li>
                  WADA-Q
                  <ul>
                    <li>和田 久 選手</li>
                  </ul>
                </li>
                <li>
                  Guts 城内
                  <ul>
                    <li>城内 政樹 選手</li>
                  </ul>
                </li>
                <li>
                  OSAMU
                  <ul>
                    <li>中嶋 修 選手</li>
                  </ul>
                </li>
                <li>
                  砂子 塾長
                  <ul>
                    <li>砂子 智彦 選手</li>
                  </ul>
                </li>
                <li>
                  井田 太陽
                  <ul>
                    <li>飯田 太陽 選手</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </TableArea>
          <TableArea title="用語">
            <Typography component="div" variant="body2" sx={{ px: 1 }}>
              <ul>
                <li>PP ・・・ ポールポジションの略です。</li>
                <li>P2W ・・・ ポールトゥウィンの略です。</li>
              </ul>
            </Typography>
          </TableArea>
          <TableArea title="記録の計算方法">
            <H3 title="開催レース">
              <Typography variant="body2" sx={{ px: 1 }}>
                <ul>
                  <li>
                    1998年Round2は決勝が開催されませんでしたが、予選が開催されたので1レースとして扱います。
                  </li>
                  <li>
                    2010年Round7は予選や決勝などレース自体が開催されませんでしたので、1レースとして扱いません。
                  </li>
                  <li>
                    2024年Round4は予選が開催されませんでしたが、決勝が開催されたので1レースとして扱います。予選未開催のため、本レースの出走順1位ドライバーはポールポジションとしてカウントしません。
                  </li>
                </ul>
              </Typography>
            </H3>
            <H3 title="出場">
              <Typography variant="body2" sx={{ px: 1 }}>
                あるレースにおけるフリー走行や予選、決勝などのいずれか1個に出場したドライバーは、出場として扱います。
                (予選でアタックしていない3rdドライバーも、公式ページの結果欄に名前があれば予選出場として扱います)
              </Typography>
            </H3>
            <H3 title="ポールポジション">
              <Typography variant="body2" sx={{ px: 1 }}>
                <ul>
                  <li>
                    2023年とそれ以前:
                    予選で最速タイムを出したドライバー1名をポールポジションとして扱います。そのペアドライバーはポールポジションとして扱いません。
                  </li>
                  <li>
                    2024年とそれ以降:
                    予選1位のマシンのドライバー2名をポールポジションとして扱います。
                  </li>
                </ul>
              </Typography>
            </H3>
            <H3 title="ポールトゥウィン">
              <Typography variant="body2" sx={{ px: 1 }}>
                予選と決勝で共に1位のドライバーをポールトゥウィンとして扱います。予選ポールポジションのドライバーのペアドライバーも対象です。
                予選のみ、または決勝のみ出場したドライバーは、乗ったマシンが予選と決勝1位でも、ポールトゥウィンとしては扱いません。
              </Typography>
            </H3>
            <H3 title="ジャンプアップ">
              <Typography variant="body2" sx={{ px: 1 }}>
                予選の順位と決勝の順位の差に基づいて算出します。予選順位が無いマシンは算出外として扱います。
                決勝グリッド順が分かれば予選順位が無くても算出できますが、そのデータが揃っていないためです。以下は一例です。
                <ul>
                  <li>
                    2020年Round6で23号車は決勝15番手から勝利していますが、予選はタイム抹消で順位無しだったため、ジャンプアップの計算の対象外して扱います。
                  </li>
                </ul>
              </Typography>
            </H3>
            <H3 title="連続記録">
              <Typography variant="body2" sx={{ px: 1 }}>
                対象の選手やメーカーがレースを欠場した場合、連続記録は途切れます。例えば欠場前のレースと欠場明けのレースの両方で優勝した場合、優勝の連続記録は2回連続とはなりません。
              </Typography>
            </H3>
            <H3 title="達成率">
              <Typography variant="body2" sx={{ px: 1 }}>
                達成率は対象の選手やメーカーが出場したレース数を分母、達成したレース数を分子とした割合です。
              </Typography>
            </H3>
            <H3 title="参戦中のみ">
              <Typography variant="body2" sx={{ px: 1 }}>
                GT500/GT300問わず、最新年のレースに参戦したドライバーやメーカーを絞り込んで表示します。例えばGT500のランキングでは、GT300に参戦中のドライバーやメーカーも参戦中として表示します。以下は一例です。
                <ul>
                  <li>
                    小暮選手はGT300に参戦中のため、GT500のドライバーランキングにも参戦中として表示します。
                  </li>
                  <li>
                    GT-RはGT300に参戦中のため、GT500の車名ランキングにも参戦中として表示します。
                  </li>
                </ul>
              </Typography>
            </H3>
          </TableArea>
          <TableArea title="免責事項">
            <Typography variant="body2" sx={{ px: 1 }}>
              本サイトでは正確なデータや集計結果の提供を心がけていますが、それを保証するものではございません。また、本サイト、及びリンク先のサイトのご利用時において利用者が被った損害に対して、当方はいかなる責任も負わないものといたします。ご承知おきください。
            </Typography>
          </TableArea>
          <TableArea title="プライバシーポリシー">
            <Typography variant="body2" sx={{ px: 1 }}>
              当サイトでは、Googleの提供するアクセス解析ツール Google Analytics
              を利用しています。 Google Analytics
              はトラフィックデータ収集のためにCookieを使用していますが、このデータは匿名で収集されており、個人を特定するものではありません。また、この機能はCookieを無効にすることで収集を拒否することが可能です。
            </Typography>
          </TableArea>
        </PageArea>
      </Box>
    ),
    []
  );
};

export default AboutPage;
