import React, { memo } from 'react';

import { Tab, Tabs } from '@mui/material';

interface TableTabsProp {
  values: { id: string; displayName: string }[];
  selectedId: string;
  onChanged: (selectedId: string) => void;
}

const TableTabs = ({
  values,
  selectedId,
  onChanged,
}: TableTabsProp): React.ReactElement => {
  const [selected, setSelected] = React.useState('');
  React.useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  return values.map((v) => v.id).includes(selected) ? (
    <Tabs
      value={selected}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile={true}
      onChange={(_e, id: string) => {
        setSelected(id);
        onChanged(id);
      }}
      style={{
        width: '100%', // 画面幅が広くても他の部品の右には表示しない
      }}
    >
      {values.map((value) => (
        <Tab key={value.id} value={value.id} label={value.displayName} />
      ))}
    </Tabs>
  ) : (
    <></>
  );
};

export default memo(TableTabs);
