import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTableData } from '../../hooks/UseData';
import { getFormatter } from '../../features/Formatter';
import { JsonTableData, toData } from '../../features/Model';
import {
  createTableData,
  IndividualTableData,
  RankingRow,
  RankingTableData,
} from '../../features/ViewModel';
import { I18n, LABEL_CATEGORY, useI18n } from '../../hooks/I18n';
import { defined, Optional } from '../../utils/Util';
import { NewIcon } from './StyledComponents';

const COLUMN_ID_RACE_ID = 'achieved_race_key_race_id-group_concat';
const COLUMN_ID_DRIVER_NAME = 'achieved_race_key_driver_name-group_concat';
const COLUMN_ID_RACE_DATE =
  'achieved_race_key_race_date-concat_value_and_other-race_kind_R';

const getAchievedDateRankFromRow = (row: RankingRow): number => {
  return row.getCell(COLUMN_ID_RACE_DATE)?.rank?.rank ?? -1;
};

const getPageTitle = (name: string, i18n: I18n): string => {
  return getFormatter('achievement')?.format(name, i18n) ?? name;
};

type AchievementItemModel = {
  total: number;
  driverName: string;
  date: string;
  isNew: boolean;
};

const createAchievementItemModel = (
  tableData: Optional<RankingTableData | IndividualTableData>,
  i18n: I18n
): Optional<AchievementItemModel> => {
  if (!(tableData instanceof RankingTableData)) {
    return undefined;
  }

  const rows = tableData
    .getRows()
    .sort(
      (a, b) => getAchievedDateRankFromRow(a) - getAchievedDateRankFromRow(b)
    );
  const lastRow = rows[rows.length - 1];
  if (!defined(lastRow)) {
    return undefined;
  }
  const driverName =
    lastRow
      .getCell(COLUMN_ID_DRIVER_NAME)
      ?.getValues()?.[0]
      ?.getLocalizedValue(i18n) ?? '';

  // getLocalizedValue が Promise を throw する。fetchRaceData を呼ぶため
  const date =
    lastRow
      .getCell(COLUMN_ID_RACE_ID)
      ?.getValues()?.[0]
      ?.getLocalizedValue(i18n) ?? '';

  const isNew =
    lastRow.getCell(COLUMN_ID_RACE_DATE)?.getValues()?.[0]?.getDiff(i18n) ===
    'New';
  return { total: rows.length, driverName, date, isNew };
};

const createAchievementItemElement = (
  name: string,
  linkPagePath: string,
  achievementItemModel: AchievementItemModel,
  i18n: I18n
) => {
  const newLabel = achievementItemModel.isNew ? (
    <NewIcon
      sx={{
        display: 'inline-block', //縦方向のマージンを有効化して
        verticalAlign: 'bottom', // 下から2pxに配置
        marginBottom: '2px',
        marginLeft: -1, // 左との間隔を狭める
      }}
    ></NewIcon>
  ) : (
    <></>
  );

  const readMore = (
    <Link to={linkPagePath}>
      {i18n.LABELS.localize('link_detail', LABEL_CATEGORY.ACHIEVEMENT)}
    </Link>
  );
  const people_unit = i18n.LABELS.localize(
    'people_unit',
    LABEL_CATEGORY.ACHIEVEMENT
  );
  const latest = i18n.LABELS.localize('latest', LABEL_CATEGORY.ACHIEVEMENT);

  const flexSx: SxProps = {
    display: 'flex', // 横幅はコンテンツに合わせる
    flexDirection: 'row', // 横方向に並べる
    width: '100%', // 親の幅と合わせる
    columnGap: 2, // テキスト間の幅
    alignItems: 'baseline', // タイトルと人数を下で揃える
  };
  const item = (
    <>
      <Box sx={{ ...flexSx, mb: 1 }}>
        <Typography variant="h3" fontWeight="900">
          {getPageTitle(name, i18n)}
        </Typography>
        <Typography variant="body2">
          {achievementItemModel.total}
          {people_unit}
        </Typography>
        <Typography variant="body2">{readMore}</Typography>
      </Box>
      <Box sx={flexSx}>
        <Typography variant="body2" sx={{ flexShrink: '0' }}>
          {latest}
        </Typography>
        <Box
          sx={{
            ...flexSx,
            flexGrow: '1',
            flexWrap: 'wrap', // はみ出したら改行
            alignItems: 'center',
          }}
        >
          <Typography variant="body2">{achievementItemModel.date}</Typography>
          <Typography variant="body2">
            {achievementItemModel.driverName}
          </Typography>
          {newLabel}
        </Box>
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        boxShadow: 2,
        borderWidth: 0,
        backgroundColor: 'white',
        borderStyle: 'solid',
        m: 1, //マージンがないとboxShadowが表示されない
        p: 2,
      }}
    >
      {item}
    </Box>
  );
};

const AchievementPageItem: React.FC<{
  name: string;
  tablePath: string;
  linkPagePath: string;
}> = ({ name, tablePath, linkPagePath }) => {
  const i18n = useI18n();
  const { isLoading, data: tableData } = useTableData(
    tablePath,
    undefined,
    (data: JsonTableData) => createTableData(toData(data))
  );
  const achievementItemModel = createAchievementItemModel(tableData, i18n);
  if (isLoading || !defined(achievementItemModel)) {
    return <></>;
  }
  return createAchievementItemElement(
    name,
    linkPagePath,
    achievementItemModel,
    i18n
  );
};

export default AchievementPageItem;
