import React from 'react';
import { useHistory } from 'react-router-dom';
import { defined } from '../utils/Util';

export const SendAnalyticsData: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    const unregister = history.listen(() => {
      if (defined(window)) {
        window.gtag('event', 'page_view', {
          page_location: window.location.href,
          // 設定しなくてもChromeプラグインを有効にすればDebugViewに表示される
          // debug_view: true,
        });
      }
    });
    return () => {
      unregister();
    };
  }, []);
  return <></>;
};
